import { inject, Injectable } from '@angular/core';
import { Slice } from '@prismicio/client';
import { ProductCatalogService } from '@yol-digital/ms-client';
import { CountDownSlice } from 'countdown';
import { ImageSet, Promotion, SliceClass } from 'interfaces';
import { OfferCtaSlice, OfferStickerSlice } from 'offer-box';
import {
  Offer,
  OfferDocument,
  OfferDocumentDataBodyCountDownSlice,
  OfferDocumentDataBodyCountDownSliceItem,
  OfferDocumentDataBodyOfferCtaSlice,
  OfferDocumentDataBodySquircleSlice,
  prismicToHtmlString,
} from 'prismic';
import { Product, ProductListItem, ProductService } from 'product-shared';
import { createLinkFromString } from 'utils';
import CatalogResponse = ProductCatalogService.CatalogResponse;

@Injectable({
  providedIn: 'root',
})
export class PrismicComponentsService {
  productService = inject(ProductService);

  convertOffer(offerData: OfferDocument, productFromPC?: CatalogResponse, promotion?: Promotion) {
    const content = prismicToHtmlString(offerData.data.offer_content);
    const offerImage = offerData.data.offer_image;
    let productListItem: ProductListItem;
    if (productFromPC) {
      const product = new Product(productFromPC);
      productListItem = {
        product,
        promotion,
      };
    }
    let images: ImageSet | undefined;
    // An image can be empty however Prismic seems to return an object anyway
    if (offerImage && offerImage.url && offerImage.dimensions) {
      images = new ImageSet(offerImage);
    }
    const slices: SliceClass[] = offerData.data.body
      ?.map((sliceData: Slice) => {
        switch (sliceData.slice_type) {
          case 'offer_cta':
            return this.convertOfferCta(sliceData as OfferDocumentDataBodyOfferCtaSlice);
          case 'count_down':
            return this.convertOfferCountDown(sliceData as OfferDocumentDataBodyCountDownSlice);
          case 'promo_banner':
            return this.convertOfferSticker(sliceData as OfferDocumentDataBodySquircleSlice);
          default:
            console.error('Offer slice type is not configured', sliceData.slice_type);
        }
      })
      .filter((d: SliceClass) => d);

    return new Offer(offerData, content, images, productListItem, slices);
  }

  convertOfferCta(sliceData: OfferDocumentDataBodyOfferCtaSlice) {
    // temporary workaround for texts with language on beggining (our awards section for example)
    // check if the string starts with /en/ or /it/ or /de/ or /fr/
    // if so, replace it with empty string
    const sliceDataLink = sliceData.primary.button_link;
    // button_link is a text field, not a link field in Prismic.
    // So - best to create a document/web link variable using the text field.
    const buttonLink = createLinkFromString(sliceDataLink);

    return new OfferCtaSlice(sliceData, sliceData.primary.button_text, buttonLink, sliceData.primary.button_color);
  }

  convertOfferCountDown(sliceData: OfferDocumentDataBodyCountDownSlice) {
    if (!sliceData.items) {
      return;
    }

    // Countdowns can have multiple date times so we take the earliest one that isn't in the past.
    // Dates come as JSON strings in the format: 2022-01-01T23:00:00+0000
    // We can just use string comparisons for simplicity as
    // '2021-01-01T23:00:00+0000' < '2022-01-01T23:00:00+0000'

    // Create a list of date strings and sort it
    const end_dates: Array<string> = sliceData.items
      .map((v: OfferDocumentDataBodyCountDownSliceItem) => v.count_down_date as string)
      .sort();
    const now = new Date();

    for (const d of end_dates) {
      const countdown_date = new Date(d);
      if (countdown_date.getTime() > now.getTime()) {
        // Break out as soon as we have a date. The list is sorted so this will be the earliest
        return new CountDownSlice(sliceData, d);
      }
    }
  }

  convertOfferSticker(sliceData: OfferDocumentDataBodySquircleSlice) {
    return new OfferStickerSlice(sliceData);
  }
}
